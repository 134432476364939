<template>
  <div id="newlyBuild">
    <div class="aa">
      <el-row>
        <el-button @click="$router.go(-1)" style="width: 112px">取消</el-button>
        <el-button type="warning" style="margin-left: 40px" @click="danxuan">
          添加单选题
        </el-button>
        <el-button type="info" style="background: #2791fbff" @click="xuanze">
          添加多选题
        </el-button>
        <el-button type="success" style="margin-right: 40px" @click="tiankong">
          添加填空题
        </el-button>
        <el-button type="primary" @click="submitForm()" style="width: 112px">
          发布
        </el-button>
      </el-row>
    </div>

    <template>
      <div class="all">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          label-position="left"
        >
          <el-form-item label="信息采集名称" :required="true">
            <el-input
              type="text"
              placeholder="请输入信息采集名称"
              v-model="ruleForm.naire_name"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="信息采集说明" class="pl5">
            <el-input
              type="textarea"
              placeholder="请输入信息采集说明"
              v-model="ruleForm.description"
              style="resize: none"
              maxlength="140"
              rows="6"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="采集方式" class="pl5">
            <el-radio-group v-model="ruleForm.type" :disabled="!!isedit">
              <el-radio label="1">
                行为触发采集
                <helpIcon
                  style="margin-left: 6px"
                  content="可以指定某个行为，学员触发后自动引导填写"
                ></helpIcon>
              </el-radio>
              <el-radio label="2">
                链接分享采集
                <helpIcon
                  style="margin-left: 6px"
                  content="可以发送链接给学员填写信息采集，适用于考试、测试、资料采集等"
                ></helpIcon>
              </el-radio>
            </el-radio-group>
            <span style="color: red; margin-left: 40px">
              提示：发布后不可修改采集方式！
            </span>
          </el-form-item>

          <template v-if="ruleForm.type == 1">
            <el-form-item class="pl5" label="触发行为">
              <el-radio-group
                :disabled="!!isedit"
                v-model="ruleForm.naire_position"
                style="margin-right: 40px"
                @change="selceCourse = []"
              >
                <el-radio label="1">
                  登录后
                  <helpIcon
                    style="margin-left: 6px"
                    content="登录后行为只允许同时启用一份信息采集，当前信息采集发布后，已有登录后行为的信息采集将自动停用！"
                  ></helpIcon>
                </el-radio>
                <el-radio label="2">报名时</el-radio>
                <el-radio label="5">报名后</el-radio>
                <el-radio label="3">兑换课程时</el-radio>
                <el-radio label="4">购买会员时</el-radio>
              </el-radio-group>
              <span style="color: red">提示：发布后不可修改触发行为！</span>
            </el-form-item>
            <el-form-item
              class="pl5"
              label="重复采集"
              v-if="ruleForm.naire_position == 5"
            >
              <div class="switch flex-center">
                <el-switch
                  v-model="ruleForm.is_repeat"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
                <span class="ml20" style="color: red">
                  选择内容为多个时，开启后，用户将在每个内容下重复填写信息
                </span>
              </div>
            </el-form-item>

            <el-form-item
              label="选择内容"
              :required="true"
              v-if="
                ruleForm.naire_position == 2 || ruleForm.naire_position == 5
              "
            >
              <div class="selecting">
                <el-button style="width: 160px" @click="kecheng">
                  +选择内容
                </el-button>
                <div class="select-contain">
                  <div class="list">
                    <el-row v-for="(item, index) in selceCourse" :key="index">
                      <el-col :span="16">
                        <div class="flex-center">
                          <div class="tu">
                            <img
                              :src="
                                item.photo || item.cover_img || item.img_url
                              "
                              alt=""
                            />
                          </div>
                          <div class="name" :title="item.name">
                            {{ item.name }}
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <span v-if="item.course_type">
                          {{ item.course_type | getCoursetype }}
                        </span>
                        <span v-else-if="item.open_class_id">公开课</span>
                        <span v-else-if="item.ed_id">资料</span>
                        <span v-else-if="item.graphic_id">图文</span>
                      </el-col>
                      <el-col :span="4" class="redcolor">
                        <div class="price" v-if="item.open_class_id">
                          <span v-if="item.play_type == 3" :title="item.price">
                            ￥{{ item.price }}
                          </span>
                          <span v-else-if="item.play_type == 1">免费</span>
                          <span v-else-if="item.play_type == 2">密码</span>
                        </div>
                        <div class="price" v-else>
                          <span v-if="item.price_type == 1" :title="item.price">
                            ￥{{ item.price }}
                          </span>
                          <span v-else-if="item.price_type == 2">免费</span>
                          <span v-else-if="item.price_type == 3">密码</span>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <el-popconfirm
                          @confirm="confirmCouseselect(index)"
                          title="是否确定移除该内容？"
                        >
                          <el-button type="text" slot="reference">
                            移除
                          </el-button>
                        </el-popconfirm>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-form-item>

            <!-- selectActive -->
            <el-form-item
              label="兑换码活动"
              :required="true"
              v-if="ruleForm.naire_position == 3"
            >
              <div class="selecting">
                <el-button style="width: 160px" @click="selectActive = true">
                  + 选择活动
                </el-button>
                <div class="select-contain">
                  <div class="list">
                    <el-row
                      v-for="(item, index) in singleselectcontentval3List"
                      :key="index"
                    >
                      <el-col :span="10">
                        <div class="flex-center">
                          <div class="name" :title="item.name">
                            {{ item.name }}
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <span>
                          {{
                            item.validity
                              | formatTimeStamp('YYYY-MM-dd hh:mm:ss')
                          }}
                          过期
                        </span>
                      </el-col>
                      <el-col :span="4">
                        <span class="bluecolor" v-if="item.out_date">
                          进行中
                        </span>
                        <span class="redcolor" v-else>已过期</span>
                      </el-col>

                      <el-col :span="4">
                        <el-popconfirm
                          @confirm="confirmActiveselect(index)"
                          title="是否确定移除该内容？"
                        >
                          <el-button type="text" slot="reference">
                            移除
                          </el-button>
                        </el-popconfirm>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-form-item>

            <el-form-item
              label="会员"
              :required="true"
              v-if="ruleForm.naire_position == 4"
            >
              <div class="selecting">
                <el-button style="width: 160px" @click="menbership = true">
                  +选择会员
                </el-button>
                <div class="select-contain">
                  <div class="list">
                    <el-row
                      v-for="(item, index) in singleselectcontentval4List"
                      :key="index"
                    >
                      <el-col :span="13">
                        <div class="flex-center">
                          <div class="tu">
                            <img :src="item.cover_img" alt="" />
                          </div>
                          <div class="name" :title="item.name">
                            {{ item.name }}
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="5">
                        <span v-if="item.is_show == 1">展示到网校</span>
                        <span v-else>不展示到网校</span>
                      </el-col>
                      <el-col :span="4" class="redcolor">
                        <span class="bluecolor" v-if="item.status == 1">
                          已启用
                        </span>
                        <span class="redcolor" v-else>已停用</span>
                      </el-col>
                      <el-col :span="2">
                        <el-popconfirm
                          @confirm="confirmMemberselect(index)"
                          title="是否确定移除该内容？"
                        >
                          <el-button type="text" slot="reference">
                            移除
                          </el-button>
                        </el-popconfirm>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-form-item>
          </template>
          <el-form-item label="基础采集项" class="pl5" prop="type">
            <el-checkbox-group v-model="ruleForm.list">
              <el-checkbox
                v-for="(item, index_item) in options"
                :key="index_item"
                :label="item"
                name="list"
              >
                <template v-if="item.qname == '手机号'">
                  手机号（需要验证码验证）
                </template>
                <template v-else>{{ item.qname }}</template>
              </el-checkbox>
              <div class="switch flex-center">
                <el-switch
                  v-model="ruleForm.is_synchro"
                  style="width: 31px"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
                <span class="fs12 pl5">同步到学员详情</span>
                <helpIcon
                  style="margin-left: 6px; margin-bottom: 0"
                  content="开启后，学员填写的内容会同步到学员的个人中心和网校后台的学员详情，如果有多份采集，则以最后填写的为准"
                ></helpIcon>
              </div>
            </el-checkbox-group>
          </el-form-item>
        </el-form>

        <div class="question">
          <div class="top2">
            <div class="zi5">题目名称</div>
            <div class="zi6">操作</div>
          </div>
          <div class="bottom4">
            <transition-group tag="ul" name="flip-list">
              <li v-for="(i, index) in ruleForm.list" :key="i.qname">
                <template v-if="i.qtype == 1">
                  <div class="first">
                    <div class="top4">
                      <el-row>
                        <el-col :span="17">
                          <div class="zi8">{{ index + 1 }}. {{ i.qname }}</div>
                        </el-col>
                        <el-col :span="7">
                          <div class="zi9">
                            <div class="btns">
                              <el-checkbox
                                class="text"
                                v-model="i.not_null"
                                true-label="1"
                                false-label="0"
                                v-if="i.not_null == 1"
                                checked
                              >
                                必填
                              </el-checkbox>
                              <el-checkbox
                                class="text"
                                v-model="i.not_null"
                                true-label="1"
                                false-label="0"
                                v-if="i.not_null == 0"
                              >
                                必填
                              </el-checkbox>
                              <div v-if="i.qattr == 2">
                                <span class="space"></span>
                                <span class="text" @click="editquestion(index)">
                                  编辑
                                </span>
                              </div>
                              <span class="space"></span>
                              <el-button
                                type="text"
                                :disabled="index == 0"
                                @click="upDownMove(index, index - 1)"
                              >
                                上移
                              </el-button>
                              <span class="space"></span>
                              <el-button
                                type="text"
                                :disabled="index == ruleForm.list.length - 1"
                                @click="upDownMove(index, index + 1)"
                              >
                                下移
                              </el-button>
                              <span class="space"></span>
                              <span class="text" @click="del(index)">删除</span>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                    <div class="bottom5">请输入内容</div>
                  </div>
                </template>

                <template v-if="i.qtype == 2">
                  <div class="first">
                    <div class="top4">
                      <el-row>
                        <el-col :span="17">
                          <div class="zi8">{{ index + 1 }}. {{ i.qname }}</div>
                        </el-col>
                        <el-col :span="7">
                          <div class="zi9">
                            <div class="btns">
                              <el-checkbox
                                class="text"
                                v-model="i.not_null"
                                true-label="1"
                                false-label="0"
                                v-if="i.not_null == 1"
                                checked
                              >
                                必填
                              </el-checkbox>
                              <el-checkbox
                                class="text"
                                v-model="i.not_null"
                                true-label="1"
                                false-label="0"
                                v-if="i.not_null == 0"
                              >
                                必填
                              </el-checkbox>
                              <div v-if="i.qattr == 2">
                                <span class="space"></span>
                                <span class="text" @click="edit_single(index)">
                                  编辑
                                </span>
                              </div>
                              <span class="space"></span>
                              <span
                                class="text"
                                @click="upDownMove(index, index - 1)"
                              >
                                上移
                              </span>
                              <span class="space"></span>
                              <span
                                class="text"
                                @click="upDownMove(index, index + 1)"
                              >
                                下移
                              </span>
                              <span class="space"></span>
                              <span class="text" @click="del(index)">删除</span>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                    <div class="bottom6">
                      <el-radio-group v-model="ruleForm.radio2">
                        <div
                          v-for="(n, nindex) in i.option_content"
                          :key="nindex"
                          style="margin-botton: 10px"
                        >
                          <el-radio :label="n">{{ n }}</el-radio>
                        </div>
                      </el-radio-group>
                    </div>
                  </div>
                </template>

                <template v-if="i.qtype == 3">
                  <div class="first">
                    <div class="top4">
                      <el-row>
                        <el-col :span="17">
                          <div class="zi8">{{ index + 1 }}. {{ i.qname }}</div>
                        </el-col>
                        <el-col :span="7">
                          <div class="zi9">
                            <div class="btns">
                              <el-checkbox
                                class="text"
                                v-model="i.not_null"
                                true-label="1"
                                false-label="0"
                                v-if="i.not_null == 1"
                                checked
                              >
                                必填
                              </el-checkbox>
                              <el-checkbox
                                class="text"
                                v-model="i.not_null"
                                true-label="1"
                                false-label="0"
                                v-if="i.not_null == 0"
                              >
                                必填
                              </el-checkbox>
                              <div v-if="i.qattr == 2">
                                <span class="space"></span>
                                <span class="text" @click="edit_more(index)">
                                  编辑
                                </span>
                              </div>
                              <span class="space"></span>
                              <span
                                class="text"
                                @click="upDownMove(index, index - 1)"
                              >
                                上移
                              </span>
                              <span class="space"></span>
                              <span
                                class="text"
                                @click="upDownMove(index, index + 1)"
                              >
                                下移
                              </span>
                              <span class="space"></span>
                              <span class="text" @click="del(index)">删除</span>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </div>

                    <div class="bottom6">
                      <div
                        v-for="(nn, nnindex) in i.option_content"
                        :key="nnindex"
                      >
                        <el-checkbox :label="n">{{ nn }}</el-checkbox>
                      </div>
                    </div>
                  </div>
                </template>
              </li>
            </transition-group>
          </div>
        </div>

        <!-- 关联课程弹窗 -->
        <networkschool-Select
          v-if="dialogVisible"
          :dialogstatus="!!dialogVisible"
          title="选择内容"
          :is_naire="2"
          :template="ruleForm.naire_position == 5 ? 20 : 7"
          :ids="ruleForm.naire_position == 5 ? ids[3] : ids"
          tip="提示：已添加的内容不在列表显示"
          @selectChangenetworkschool="dialogVisible = false"
          @singleselectcontentval="singleselectcontentval"
        ></networkschool-Select>

        <!-- 选择会员 -->
        <networkschool-Select
          tip="提示：已添加的会员不在列表显示"
          v-if="menbership"
          :dialogstatus="!!menbership"
          title="选择会员"
          :template="8"
          :ids="ids4"
          @selectChangenetworkschool="menbership = false"
          @singleselectcontentval="singleselectcontentval4"
        ></networkschool-Select>

        <!-- 选择活动 -->
        <networkschool-Select
          tip="提示：已添加的活动不在列表显示"
          v-if="selectActive"
          :dialogstatus="!!selectActive"
          title="选择活动"
          :template="9"
          :ids="ids3"
          @selectChangenetworkschool="selectActive = false"
          @singleselectcontentval="singleselectcontentval3"
        ></networkschool-Select>

        <!-- 添加多选题弹窗 -->
        <div class="pop2">
          <el-dialog
            :visible.sync="dialogVisible2"
            width="800"
            :close-on-click-modal="false"
          >
            <div class="center2">
              <el-row :gutter="5">
                <el-col :span="3">
                  <div>
                    <span style="color: red">*</span>
                    题目名称
                  </div>
                </el-col>
                <el-col :span="21">
                  <el-input
                    type="textarea"
                    rows="4"
                    placeholder="请输入题目名称"
                    v-model="pop.pop_textarea"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-col>
              </el-row>
              <el-row :gutter="5" v-for="(i, index) in n" :key="index">
                <el-col :span="3">
                  <span style="color: red">*</span>
                  选项 {{ tran(index + 1) }}
                </el-col>
                <el-col :span="20">
                  <el-input
                    type="text"
                    :placeholder="`请输入选项${tran(index + 1)}`"
                    v-model="pop.input[index]"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-col>
                <el-col :span="1">
                  <div
                    style="
                      color: red;
                      font-size: 12px;
                      cursor: pointer;
                      margin-top: 13px;
                      width: 25px;
                    "
                    @click="del_more(index)"
                  >
                    删除
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="5">
                <el-col :span="3"></el-col>
                <el-col :span="21">
                  <el-button @click="add4">添加新选项</el-button>
                </el-col>
              </el-row>
            </div>
            <div slot="title">
              <div class="zi7">添加多选题</div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-checkbox
                v-model="pop2.checked"
                false-label="0"
                true-label="1"
                checked
                style="margin-right: 20px"
              >
                必填
              </el-checkbox>
              <el-button @click="dialogVisible2 = false">取 消</el-button>
              <el-button type="primary" @click="moreselect">确 定</el-button>
            </span>
          </el-dialog>
        </div>

        <!-- 编辑多选题弹窗 -->
        <div class="pop2">
          <el-dialog
            :visible.sync="dialogVisible7"
            width="800"
            :close-on-click-modal="false"
          >
            <div class="center2">
              <el-row :gutter="5">
                <el-col :span="3">
                  <span style="color: red">*</span>
                  题目名称
                </el-col>
                <el-col :span="21">
                  <el-input
                    type="textarea"
                    rows="4"
                    placeholder="请输入题目名称"
                    v-model="pop.pop_textarea"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-col>
              </el-row>
              <el-row
                :gutter="5"
                v-for="(ii, index2) in edit_more_option"
                :key="index2"
              >
                <el-col :span="3">
                  <span style="color: red">*</span>
                  选项 {{ tran(index2 + 1) }}
                </el-col>

                <el-col :span="20">
                  <el-input
                    type="text"
                    :placeholder="`请输入选项${tran(index2 + 1)}`"
                    v-model="edit_more_option[index2]"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-col>
                <el-col :span="1">
                  <div
                    style="
                      color: red;
                      font-size: 12px;
                      cursor: pointer;
                      margin-top: 13px;
                      width: 25px;
                    "
                    @click="del_edit_more(index2)"
                  >
                    删除
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="5">
                <el-col :span="3"></el-col>
                <el-col :span="21">
                  <el-button @click="add">添加新选项</el-button>
                </el-col>
              </el-row>
            </div>
            <div slot="title">
              <div class="zi7">编辑多选题</div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-checkbox
                v-model="pop2.checked"
                false-label="0"
                true-label="1"
                checked
                style="margin-right: 20px"
              >
                必填
              </el-checkbox>
              <el-button @click="dialogVisible7_cancle">取 消</el-button>
              <el-button type="primary" @click="dialogVisible7_button">
                确 定
              </el-button>
            </span>
          </el-dialog>
        </div>

        <!-- 添加单选题弹窗 -->
        <div class="pop2">
          <el-dialog
            :visible.sync="dialogVisible3"
            width="800"
            :close-on-click-modal="false"
          >
            <div class="center2">
              <el-row :gutter="5">
                <el-col :span="3">
                  <span style="color: red">*</span>
                  题目名称
                </el-col>
                <el-col :span="21">
                  <el-input
                    type="textarea"
                    rows="4"
                    placeholder="请输入题目名称"
                    v-model="pop2.pop2_textarea"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-col>
              </el-row>
              <el-row :gutter="5" v-for="(i, index3) in n2" :key="index3">
                <el-col :span="3">
                  <span style="color: red">*</span>
                  选项 {{ tran(index3 + 1) }}
                </el-col>

                <el-col :span="20">
                  <el-input
                    type="text"
                    :placeholder="`请输入选项${tran(index3 + 1)}`"
                    v-model="pop2.text[index3]"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-col>
                <el-col :span="1">
                  <div
                    style="
                      color: red;
                      font-size: 12px;
                      cursor: pointer;
                      margin-top: 13px;
                      width: 25px;
                    "
                    @click="del_single(index3)"
                  >
                    删除
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="5">
                <el-col :span="3"></el-col>
                <el-col :span="21">
                  <el-button @click="add3">添加新选项</el-button>
                </el-col>
              </el-row>
            </div>
            <div slot="title">
              <div class="zi7">添加单选题</div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-checkbox
                checked
                v-model="pop2.checked2"
                false-label="0"
                true-label="1"
                style="margin-right: 20px"
              >
                必填
              </el-checkbox>
              <el-button @click="dialogVisible3 = false">取 消</el-button>
              <el-button type="primary" @click="addSinggle">确 定</el-button>
            </span>
          </el-dialog>
        </div>

        <!-- 编辑添加单选题弹窗 -->
        <div class="pop2">
          <el-dialog
            :visible.sync="dialogVisible6"
            width="800px"
            :close-on-click-modal="false"
          >
            <div class="center2">
              <el-row :gutter="5">
                <el-col :span="3">
                  <span style="color: red">*</span>
                  题目名称
                </el-col>
                <el-col :span="21">
                  <el-input
                    type="textarea"
                    rows="4"
                    placeholder="请输入题目名称"
                    v-model="pop2.pop2_textarea"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-col>
              </el-row>
              <el-row
                :gutter="5"
                v-for="(i, index4) in edit_single_option"
                :key="index4"
              >
                <el-col :span="3">
                  <span style="color: red">*</span>
                  选项 {{ tran(index4 + 1) }}
                </el-col>
                <el-col :span="20">
                  <el-input
                    type="text"
                    :placeholder="`请输入选项${tran(index4 + 1)}`"
                    v-model="edit_single_option[index4]"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-col>
                <el-col :span="1">
                  <div
                    style="
                      color: red;
                      font-size: 12px;
                      cursor: pointer;
                      margin-top: 13px;
                      width: 25px;
                    "
                    @click="del_edit_single(index4)"
                  >
                    删除
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="5">
                <el-col :span="3"></el-col>
                <el-col :span="21">
                  <el-button @click="add2">添加新选项</el-button>
                </el-col>
              </el-row>
            </div>
            <div slot="title">
              <div class="zi7">编辑单选题</div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-checkbox
                checked
                v-model="pop2.checked2"
                false-label="0"
                true-label="1"
                style="margin-right: 20px"
              >
                必填
              </el-checkbox>
              <el-button @click="dialogVisible6_cancle">取 消</el-button>
              <el-button type="primary" @click="dialogVisible6_button">
                确 定
              </el-button>
            </span>
          </el-dialog>
        </div>

        <!-- 添加填空题弹窗 -->
        <div class="pop2">
          <el-dialog
            :visible.sync="dialogVisible4"
            width="800"
            :close-on-click-modal="false"
          >
            <div class="center2">
              <el-row :gutter="5">
                <el-col :span="3">题目名称</el-col>
                <el-col :span="21">
                  <el-input
                    type="textarea"
                    rows="4"
                    placeholder="请输入题目名称"
                    v-model="pop3.pop3_textarea"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-col>
              </el-row>
            </div>
            <div slot="title">
              <div class="zi7">添加填空题</div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-checkbox
                checked
                v-model="pop2.checked3"
                false-label="0"
                true-label="1"
                style="margin-right: 20px"
              >
                必填
              </el-checkbox>
              <el-button @click="dialogVisible4 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible_button">
                确 定
              </el-button>
            </span>
          </el-dialog>
        </div>

        <!-- 填空题编辑弹窗 -->
        <div class="pop2">
          <el-dialog
            :visible.sync="dialogVisible5"
            width="800"
            :close-on-click-modal="false"
          >
            <div class="center2">
              <el-row :gutter="5">
                <el-col :span="3">题目名称</el-col>
                <el-col :span="21">
                  <el-input
                    type="textarea"
                    rows="4"
                    placeholder="请输入题目名称"
                    v-model="pop3.pop3_textarea"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-col>
              </el-row>
            </div>
            <div slot="title">
              <div class="zi7">编辑填空题</div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-checkbox
                checked
                v-model="pop2.checked3"
                false-label="0"
                true-label="1"
                style="margin-right: 20px"
              >
                必填
              </el-checkbox>
              <el-button @click="dialogVisible5 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible5_button">
                确 定
              </el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </template>
    <router-view />
  </div>
</template>

<script>
/**
 * @description: 调查表
 * @author: 陈莹莹
 * @update: 陈莹莹(2020-04-3 17：29)
 */
// import bg from '@/mixin/background'
// 选择网校内容
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
export default {
  name: 'newlyBuild',

  components: {
    networkschoolSelect,
  },

  data() {
    return {
      selectActive: false,

      singleselectcontentval3List: [],

      singleselectcontentval4List: [],

      selceCourse: [],

      menbership: false,

      // 已选课程的id
      dialogVisible123: true,

      course: [],

      addcourse: [],

      value: '0',

      options2: [
        {
          value: '0',
          label: '全部课程',
        },
        {
          value: '1',
          label: '直播课',
        },
        {
          value: '2',
          label: '小班课',
        },
        {
          value: '3',
          label: '录播课',
        },
        {
          value: '4',
          label: '系列课',
        },
      ],

      options: [
        {
          qtype: 1,
          qname: '手机号',
          qattr: 1,
          not_null: 1,
        },
        {
          qtype: 1,
          qname: '真实姓名',
          qattr: 1,
          not_null: 1,
        },
        {
          qtype: 1,
          qname: '邮箱地址',
          qattr: 1,
          not_null: 1,
        },
        {
          qtype: 2,
          qname: '性别',
          option_content: ['男', '女'],
          qattr: 1,
          not_null: 1,
        },
        {
          qtype: 1,
          qname: '微信号',
          qattr: 1,
          not_null: 1,
        },
        {
          qtype: 1,
          qname: '地区',
          qattr: 1,
          not_null: 1,
        },
        {
          qtype: 1,
          qname: '学历',
          qattr: 1,
          not_null: 1,
        },
        {
          qtype: 1,
          qname: '年龄',
          qattr: 1,
          not_null: 1,
        },
        {
          qtype: 1,
          qname: '职业',
          qattr: 1,
          not_null: 1,
        },
      ],

      getEditQuestionnaire: [],

      i: '', //遍历的数值-添加多选项

      n: ['', ''], //记录项目个数-添加选项额个数

      n2: ['', ''], //记录项目个数-添加单选项个数

      dialogVisible3: false, //添加单选项

      dialogVisible: false, //关联课程弹窗

      dialogVisible2: false, //添加多选题弹窗

      dialogVisible4: false, //添加填空题弹窗

      dialogVisible5: false, //编辑填空题弹窗

      dialogVisible6: false, //编辑单选题弹窗

      dialogVisible7: false, // 编辑多选弹窗

      orderlist: [], //列表

      ruleForm: {
        naire_name: '',
        naire_position: '1', //触发行为
        is_repeat: '2', //重复采集
        type: '1', //弹出位置
        is_synchro: '1', //是否同步
        description: '', //描述
        list: [],
        relationList: [],
      },

      pop: {
        pop_textarea: '',
        input: ['', ''],
        checked: true,
      },

      pop3: {
        pop3_textarea: '', //添加填空题
        checked: true,
      },

      pop2: {
        pop2_textarea: '', //添加单选题
        text: ['', ''], //选项
        checked: '',
        checked2: '',
        checked3: '',
      },

      keyword: '',

      allNum: '',

      pageNum: '',

      key: '',

      status: 0,

      removeIds: [],

      dialogVisible5_index: '',

      edit_single_option: [],

      dialogVisible6_index: '',

      dialogVisible7_index: '',

      edit_more_option: [],
    }
  },

  computed: {
    // 选择直播的id
    idsCourse() {
      return this.selceCourse
        .filter(item => item.course_id)
        .map(item2 => item2.course_id)
    },

    // 选择公开课的id
    idsOpencourse() {
      return this.selceCourse
        .filter(item => item.open_class_id)
        .map(item2 => item2.open_class_id)
    },

    // 选择资料的id
    idsEd() {
      return this.selceCourse
        .filter(item => item.ed_id)
        .map(item2 => item2.ed_id)
    },

    //图文的id
    idsGc() {
      return this.selceCourse
        .filter(item => item.graphic_id)
        .map(item2 => item2.graphic_id)
    },

    ids() {
      const arr = {}
      arr[0] = this.idsCourse
      arr[1] = this.idsOpencourse
      arr[2] = this.idsEd
      arr[3] = this.idsGc
      return arr
    },

    // 选择活动
    ids3() {
      return this.singleselectcontentval3List.map(item => item.redeem_id)
    },

    // 选择会员
    ids4() {
      return this.singleselectcontentval4List.map(item => item.member_id)
    },

    // 当前是否是编辑状态，链接路径带id为编辑，不带id为新建
    isedit() {
      return this.$route.query.naire_id
    },
  },

  filters: {
    getCoursetype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        default:
          text = '系列课'
          break
      }
      return text
    },
  },

  watch: {},

  created() {
    this.getEditQuestionnairelist()
  },

  methods: {
    // 移除选择的课程
    confirmCouseselect(index) {
      this.selceCourse.splice(index, 1)
    },

    // 移除选择的会员
    confirmMemberselect(index) {
      this.singleselectcontentval4List.splice(index, 1)
    },

    // 移除选择的活动
    confirmActiveselect(index) {
      this.singleselectcontentval3List.splice(index, 1)
    },

    // 报名时 course_id open_class_id ed_id
    // 兑换课程时 redeem_id
    //  购买会员时  member_id

    // 获取会员选择数据
    singleselectcontentval4(val) {
      this.singleselectcontentval4List.push(...val)
    },

    // 获取活动选择数据
    singleselectcontentval3(val) {
      this.singleselectcontentval3List.push(...val)
    },

    // 获取课程选择数据
    singleselectcontentval(val) {
      this.selceCourse.push(...val)

      // 直播课
      const livecourse = this.selceCourse.filter(item => item.course_id)
      // 公开课
      const opencourse = this.selceCourse.filter(item => item.open_class_id)
      // 资料
      const edcourse = this.selceCourse.filter(item => item.ed_id)
      // 图文
      const gcCourse = this.selceCourse.filter(item => item.graphic_id)

      this.selceCourse = livecourse.concat(opencourse, edcourse, gcCourse)
    },

    positionfirsst() {
      this.$message({
        message: '该弹出位置已有调查表进行中，需停用后方可新建',
        type: 'warning',
      })
    },

    dialogVisible7_cancle() {
      this.dialogVisible7 = false
      this.ruleForm.list[
        this.dialogVisible7_index
      ].option_content = this.ruleForm.list[
        this.dialogVisible7_index
      ].option_content.filter(v => v != '')
    },

    dialogVisible6_cancle() {
      this.dialogVisible6 = false
      this.ruleForm.list[
        this.dialogVisible6_index
      ].option_content = this.ruleForm.list[
        this.dialogVisible6_index
      ].option_content.filter(v => v != '')
    },

    del_edit_single(val) {
      if (this.edit_single_option.length < 2) {
        this.$message({
          message: '题目必须保证有一个选项',
          type: 'warning',
        })
      } else {
        this.$confirm('是否删除该选项？', '删除选项', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.edit_single_option.splice(val, 1)
        })
      }
    },

    del_edit_more(val) {
      if (this.edit_more_option.length < 2) {
        this.$message({
          message: '题目必须保证有一个选项',
          type: 'warning',
        })
      } else {
        this.$confirm('是否删除该该选项？', '删除选项', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.edit_more_option.splice(val, 1)
        })
      }
    },

    del_single(val) {
      if (this.n2.length < 2) {
        this.$message({
          message: '题目必须保证有一个选项',
          type: 'warning',
        })
      } else {
        this.$confirm('是否删除该该选项？', '删除选项', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.n2.splice(val, 1)
          this.pop2.text.splice(val, 1)
        })
      }
    },

    del_more(val) {
      if (this.n.length < 2) {
        this.$message({
          message: '题目必须保证有一个选项',
          type: 'warning',
        })
      } else {
        this.$confirm('是否删除该该选项？', '删除选项', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.n.splice(val, 1)
          this.pop.input.splice(val, 1)
        })
      }
    },

    tiankong() {
      this.pop3.pop3_textarea = ''
      this.pop2.checked3 = '1'
      this.dialogVisible4 = true
    },

    //填空编辑
    editquestion(val) {
      this.dialogVisible5 = true
      this.pop3.pop3_textarea = this.ruleForm.list[val].qname
      this.dialogVisible5_index = val
      this.pop2.checked3 = this.ruleForm.list[val].not_null
    },

    dialogVisible5_button() {
      this.ruleForm.list[
        this.dialogVisible5_index
      ].qname = this.pop3.pop3_textarea
      this.ruleForm.list[
        this.dialogVisible5_index
      ].not_null = this.pop2.checked3
      this.dialogVisible5 = false
    },

    //单选编辑
    edit_single(val) {
      this.dialogVisible6 = true
      this.pop2.pop2_textarea = this.ruleForm.list[val].qname
      this.dialogVisible6_index = val
      this.edit_single_option = this.ruleForm.list[val].option_content
      this.pop2.checked2 = this.ruleForm.list[val].not_null
    },

    dialogVisible6_button() {
      this.ruleForm.list[
        this.dialogVisible6_index
      ].qname = this.pop2.pop2_textarea
      this.ruleForm.list[
        this.dialogVisible6_index
      ].not_null = this.pop2.checked2
      let mm = this.edit_single_option.filter(v => v == '')
      if (mm.length > 0) {
        this.$message({
          message: '请输入内容',
          type: 'warning',
        })
      } else {
        this.ruleForm.list[
          this.dialogVisible6_index
        ].option_content = this.ruleForm.list[
          this.dialogVisible6_index
        ].option_content
        this.dialogVisible6 = false
      }
    },

    //多选项编辑
    edit_more(val) {
      this.dialogVisible7 = true
      this.pop.pop_textarea = this.ruleForm.list[val].qname
      this.dialogVisible7_index = val
      this.edit_more_option = this.ruleForm.list[val].option_content
      this.pop2.checked = this.ruleForm.list[val].not_null
    },

    dialogVisible7_button() {
      this.ruleForm.list[
        this.dialogVisible7_index
      ].qname = this.pop.pop_textarea
      this.ruleForm.list[this.dialogVisible7_index].not_null = this.pop2.checked
      let mm = this.edit_more_option.filter(v => v == '')
      if (mm.length > 0) {
        this.$message({
          message: '请输入内容',
          type: 'warning',
        })
      } else {
        this.ruleForm.list[
          this.dialogVisible7_index
        ].option_content = this.ruleForm.list[
          this.dialogVisible7_index
        ].option_content
        this.dialogVisible7 = false
      }
    },

    changeSelectList(list) {
      this.ruleForm.addcourse = list
      this.ruleForm.coursen = this.ruleForm.addcourse.length
    },

    //获取编辑列表
    getEditQuestionnairelist() {
      if (this.$route.query.naire_id) {
        this.$http({
          url: '/PcSurvey/getEditQuestionnaire',
          data: {
            naire_id: this.$route.query.naire_id,
          },
          callback: ({ data, code }) => {
            if (code == 200) {
              // 记录原始数据
              const value = []
              /**
               * 这里使用qname去检索数据，如果以后基础信息采集项的名字有修改，请与后台同步，旧有的数据不能使用了，
               * 要改成自定义项，如姓名=》真实姓名，姓名改成自定义项，以来兼容以前的数据
               */
              data.list.forEach(item => {
                if (item.qattr == 1) {
                  const v = this.options.filter(v => v.qname === item.qname)[0]

                  try {
                    v.not_null = item.not_null
                  } catch (e) {
                    console.log(e)
                  }

                  value.push(v)
                } else {
                  value.push(item)
                }
              })

              this.ruleForm = _.assign({}, data, { list: value })
              switch (Number(data.naire_position)) {
                case 2:
                case 5:
                  this.selceCourse = data.relationList
                  break
                case 3:
                  this.singleselectcontentval3List = data.relationList
                  break
                case 4:
                  this.singleselectcontentval4List = data.relationList
                  break
              }
            }
          },
          error: error => {
            if (error.code == 204) {
              this.getEditQuestionnaire = []
            }
          },
        })
      }
    },

    kecheng() {
      this.dialogVisible = true
    },

    //删除题目
    del(item) {
      this.$confirm('是否删除该题目？无法撤销！', '删除题目', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.ruleForm.list.splice(item, 1)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    //删除关联课程
    delcourse(item) {
      this.ruleForm.addcourse.splice(item, 1)
    },

    //添加关联课程
    courseadd(item) {
      if (
        JSON.stringify(this.ruleForm.addcourse).indexOf(
          JSON.stringify(this.course[item])
        ) == -1
      ) {
        this.ruleForm.addcourse.push(this.course[item])
        // this.ruleForm.addcourse.push(this.course[item])
      }
    },

    //添加多选项
    moreselect() {
      let hh = this.pop.input.filter(v => v == '')
      if (
        this.pop.pop_textarea == '' ||
        this.pop.input.length < 1 ||
        hh.length > 0
      ) {
        this.$message({
          message: '请输入内容',
          type: 'warning',
        })
      } else {
        this.ruleForm.list.push({
          qtype: 3,
          qname: this.pop.pop_textarea,
          qattr: 2,
          option_content: this.pop.input,
          not_null: this.pop2.checked,
        })
        this.dialogVisible2 = false
        this.pop.pop_textarea = ''
        this.pop.input = ['', '']
        this.n = ['', '']
        this.$message({
          message: '添加题目成功！',
          type: 'success',
        })
      }
    },

    //添加单选题
    addSinggle() {
      let nn = this.pop2.text.filter(v => v == '')
      if (
        this.pop2.pop2_textarea == '' ||
        this.pop2.text.length < 1 ||
        nn.length > 0
      ) {
        this.$message({
          message: '请输入内容',
          type: 'warning',
        })
      } else {
        this.ruleForm.list.push({
          qtype: 2,
          qname: this.pop2.pop2_textarea,
          qattr: 2,
          option_content: this.pop2.text,
          not_null: this.pop2.checked2,
        })
        this.dialogVisible3 = false
        this.pop2.pop2_textarea = ''
        this.pop2.text = ['', '']
        this.n2 = ['', '']
        this.$message({
          message: '添加题目成功！',
          type: 'success',
        })
      }
    },

    //添加填空题
    dialogVisible_button() {
      if (this.pop3.pop3_textarea == '') {
        this.$message({
          message: '请输入内容',
          type: 'warning',
        })
      } else {
        this.ruleForm.list.push({
          qtype: 1,
          qname: this.pop3.pop3_textarea,
          qattr: 2,
          not_null: this.pop2.checked3,
        })
        this.dialogVisible4 = false
        this.pop3.pop3_textarea = ''
        this.$message({
          message: '添加题目成功！',
          type: 'success',
        })
      }
    },

    // 上移 下移
    upDownMove(index, newIndex) {
      if (index === 0 && newIndex < index) return
      if (index === this.ruleForm.list.length - 1 && newIndex > index) return
      const oldData = this.ruleForm.list[newIndex]
      this.ruleForm.list.splice(newIndex, 1, this.ruleForm.list[index])
      this.ruleForm.list.splice(index, 1, oldData)
    },

    tran(item) {
      switch (item) {
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
        case 7:
          return '七'
        case 8:
          return '八'
        case 9:
          return '九'
        case 10:
          return '十'
      }
    },

    danxuan() {
      this.pop2.checked2 = '1'
      this.dialogVisible3 = true
    },

    xuanze() {
      this.pop2.checked = '1'
      this.dialogVisible2 = true
    },

    //单选项添加个数控制
    add2() {
      if (this.edit_single_option.length < 10) {
        this.edit_single_option.push('')
      } else {
        this.$message({
          message: '最多可添加10项',
          type: 'warning',
        })
      }
    },

    //添加多选
    add3() {
      if (this.n2.length < 10) {
        this.n2.push('')
        this.pop2.text.push('')
      } else {
        this.$message({
          message: '最多可添加10项',
          type: 'warning',
        })
      }
    },

    //添加d单选
    add4() {
      if (this.n.length < 10) {
        this.n.push('')
        this.pop.input.push('')
      } else {
        this.$message({
          message: '最多可添加10项',
          type: 'warning',
        })
      }
    },

    //多选项添加控制
    add() {
      if (this.edit_more_option.length < 10) {
        this.edit_more_option.push('')
      } else {
        this.$message({
          message: '最多可添加10项',
          type: 'warning',
        })
      }
    },

    //关键字搜索
    doSeach(e) {
      const keyCode = window.event ? e.keyCode : e.which
      if (keyCode == 13) {
        // this.currentPage = 1
        this.getDate()
      }
    },

    // 设置删除的问题ID
    setRemoveQsId(id) {
      const index = this.removeIds.indexOf(id)
      if (index > -1) {
        this.removeIds.splice(index, 1, id)
      } else {
        this.removeIds.push(id)
      }
    },

    convertData(list) {
      // 报名时 course_id open_class_id ed_id
      // 兑换课程时 redeem_id
      //  购买会员时  member_id

      let arr = []
      list.forEach((item, index) => {
        arr.push({
          relation_id: '',
          type: '',
        })
        if (item.course_id) {
          arr[index].relation_id = item.course_id
          arr[index].type = 1
        } else if (item.open_class_id) {
          arr[index].relation_id = item.open_class_id
          arr[index].type = 2
        } else if (item.ed_id) {
          arr[index].relation_id = item.ed_id
          arr[index].type = 3
        } else if (item.redeem_id) {
          arr[index].relation_id = item.redeem_id
          arr[index].type = 4
        } else if (item.member_id) {
          arr[index].relation_id = item.member_id
          arr[index].type = 5
        } else if (item.graphic_id) {
          arr[index].relation_id = item.graphic_id
          arr[index].type = 6
        }
      })
      this.ruleForm.relationList = arr
    },

    //发布
    submitForm() {
      this.ruleForm = _.assign({}, this.ruleForm, {
        naire_name: this.ruleForm.naire_name.trim(),
        description: this.ruleForm.description.trim(),
      })

      if (!this.ruleForm.naire_name) {
        this.$root.prompt({
          msg: '请输入信息采集名称',
        })
        return
      }

      if (this.ruleForm.list.length == 0) {
        this.$root.prompt({
          msg: '至少添加一项采集题目！',
        })
        return
      }
      switch (Number(this.ruleForm.naire_position)) {
        case 2:
        case 5:
          this.convertData(this.selceCourse)
          break
        case 3:
          this.convertData(this.singleselectcontentval3List)
          break
        case 4:
          this.convertData(this.singleselectcontentval4List)
          break
      }

      if (
        (Number(this.ruleForm.naire_position) == 2 ||
          Number(this.ruleForm.naire_position) == 5) &&
        this.ruleForm.relationList.length == 0
      ) {
        this.$root.prompt({
          msg: '请选择内容',
        })
        return
      }
      if (
        Number(this.ruleForm.naire_position) == 3 &&
        this.ruleForm.relationList.length == 0
      ) {
        this.$root.prompt({
          msg: '请选择活动',
        })
        return
      }
      if (
        Number(this.ruleForm.naire_position) == 4 &&
        this.ruleForm.relationList.length == 0
      ) {
        this.$root.prompt({
          msg: '请选择会员',
        })
        return
      }

      if (this.$route.query.naire_id) {
        this.$http({
          url: 'PcSurvey/editQuestionnaire',
          data: _.assign({}, this.ruleForm, {
            naire_id: this.$route.query.naire_id,
          }),

          callback: ({ code }) => {
            if (code == 200) {
              this.$router.go(-1)
            }
          },
        })
      } else {
        this.$http({
          url: '/PcSurvey/add',
          data: _.assign({}, this.ruleForm, {
            naire_id: this.$route.query.naire_id,
          }),
          callback: ({ code }) => {
            if (code == 200) {
              this.$router.go(-1)
            }
          },
          error: error => {
            if (error.code == 204) {
              this.course = []
              this.total = error.total
            }
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scope>
.popover {
  margin-bottom: 3px;
}
.flip-list-move {
  transition: transform 0.35s;
}

#newlyBuild {
  .el-textarea__inner {
    resize: none;
    height: 120px;
  }

  .aa {
    z-index: 2;
    min-width: 1300px;
    position: fixed;
    box-shadow: 0px -2px 4px #0000000f;
    background: rgba(255, 255, 255, 1);
    bottom: 0;
    height: 70px;
    left: 0;
    width: 100%;

    .el-row {
      display: flex;
      margin-top: 15px;
      width: 100%;
      justify-content: center;
    }
  }

  .all {
    padding: 20px 20px 70px 20px;
    background-color: rgba(255, 255, 255, 1);

    .selecting {
      .el-input {
        .el-input__inner {
          cursor: pointer;
        }
      }

      .select-contain {
        .list {
          .tu {
            width: 40px;
            height: 23px;
            background-color: #f5f5f5;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: calc(100% - 50px);
            font-size: 13px;
            color: #333333;
          }
        }
      }
    }

    .clear {
      clear: both;
    }

    .btns {
      @extend %btns;
    }

    .el-form {
      .el-form-item {
        .el-checkbox-group {
          background: rgba(245, 245, 245, 1);
          padding-left: 20px;
        }

        .el-radio-group {
          i {
            margin-left: 10px;
            height: 14px;
            width: 14px;
            color: grey;
          }
        }
      }
    }

    .question {
      .top2 {
        margin-bottom: 20px;
        height: 21px;
        padding: 12px 20px;
        font-size: 14px;
        background: rgba(245, 245, 245, 1);
        border-radius: 1px;

        .zi5 {
          float: left;
        }

        .zi6 {
          float: right;
        }
      }

      .bottom4 {
        .first {
          margin-bottom: 20px;
          border: 1px solid rgba(232, 232, 232, 1);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
          border-radius: 2px;

          .top4 {
            padding: 10px 20px 10px 20px;
            min-height: 19px;
            font-size: 14px;

            .zi8 {
              float: left;
              line-height: 18px;
            }

            .zi9 {
              float: right;

              .btns {
                @extend %btns;
              }
            }
          }

          .bottom5 {
            padding-top: 13px;
            padding-left: 20px;
            border-top: 1px solid rgba(232, 232, 232, 1);
            font-size: 14px;
            color: rgba(204, 204, 204, 1);
            line-height: 19px;
            height: 30px;
          }

          .bottom6 {
            padding-top: 13px;
            padding-left: 20px;
            border-top: 1px solid rgba(232, 232, 232, 1);
            font-size: 14px;
            color: rgba(204, 204, 204, 1);
            line-height: 19px;
            min-height: 18px;
            padding-bottom: 9px;

            .el-checkbox {
              margin-bottom: 5px;
              overflow: hidden;
            }

            .el-radio {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .pop1 {
      .el-dialog {
        min-width: 955px;
        overflow: hidden;

        .el-dialog__header {
          padding: 0px;
          height: 48px;
          color: rgba(51, 51, 51, 1);
          background: rgba(245, 245, 245, 1);
        }

        .el-dialog__body {
          padding: 0px;
        }

        .zi1 {
          float: left;
          margin-top: 15px;
          margin-left: 2%;
        }

        .zi2 {
          float: left;
          margin-left: 35%;
          margin-top: 15px;
        }

        .center {
          height: 500px;
          width: 100%;

          .left {
            box-sizing: border-box;
            float: left;
            width: calc(50% - 1px);
            height: 100%;

            .el-row {
              padding: 20px;
              height: 16%;
            }

            .bottom {
              padding: 0px 20px;
              overflow-y: auto;
              height: 85%;

              ul {
                li {
                  height: 38px;
                  margin-bottom: 20px;

                  .tu1 {
                    width: 68px;
                    height: 38px;
                    background: rgba(110, 110, 110, 1);
                    float: left;

                    img {
                      width: 68px;
                      height: 38px;
                    }
                  }

                  .zi {
                    float: left;
                    margin-left: 10px;

                    .zi3 {
                      height: 12px;
                      overflow: hidden;
                      width: 300px;
                      font-size: 12px;
                      color: rgba(51, 51, 51, 1);
                    }

                    .zi4 {
                      color: rgba(153, 153, 153, 1);
                      font-size: 12px;
                      margin-top: 12px;
                    }
                  }

                  .buttonn {
                    float: right;
                  }
                }
              }
            }
          }

          .right {
            border-left: 1px solid rgba(236, 236, 236, 1);
            float: right;
            width: 50%;
            height: 100%;

            .top1 {
              padding: 20px 20px 0px 20px;
              height: 87%;
              background-color: #fff;
              overflow-y: auto;

              ul {
                li {
                  height: 38px;
                  margin-bottom: 20px;

                  .tu1 {
                    width: 68px;
                    height: 38px;
                    background: rgba(110, 110, 110, 1);
                    float: left;
                  }

                  .zi {
                    float: left;
                    margin-left: 10px;

                    .zi3 {
                      height: 12px;
                      overflow: hidden;
                      width: 300px;
                      font-size: 12px;
                      color: rgba(51, 51, 51, 1);
                    }

                    .zi4 {
                      color: rgba(153, 153, 153, 1);
                      font-size: 12px;
                      margin-top: 12px;
                    }
                  }

                  .buttonn {
                    float: right;
                  }
                }
              }

              li:last-child {
                margin-bottom: 0px;
              }
            }

            .bottom1 {
              height: 13%;
              border-top: 1px solid rgba(236, 236, 236, 1);

              .el-button {
                margin-top: 5px;
                float: right;
                margin-right: 20px;
              }
            }
          }
        }
      }
    }

    .pop2 {
      width: 800px;
      .center2 {
        .el-row {
          margin-bottom: 20px;
          .el-col:first-child {
            margin-top: 8px;
          }
        }
      }

      .zi7 {
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
}

.question .el-radio__label,
.question .el-checkbox__label {
  word-break: break-all;
  white-space: normal;
  line-height: 1.4;
  width: 97%;
  display: inline-block;
}
</style>
